/**
 * ATTENTION: This file is auto generated by using "prepareTemplates".
 * Do not change the content!
 *
 */

/**
 * Library Index height-animation to autogenerate all the components and extensions
 * Used by "prepareHeightAnimations"
 */

import HeightAnimation from './height-animation/HeightAnimation'
export * from './height-animation/HeightAnimation'
export default HeightAnimation
