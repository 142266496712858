/**
 * ATTENTION: This file is auto generated by using "prepareTemplates".
 * Do not change the content!
 *
 */

/**
 * Library Index section to autogenerate all the components and extensions
 * Used by "prepareSections"
 */

import Section from './section/Section'
export * from './section/Section'
export default Section
